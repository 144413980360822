<template>
    <div class="p-8 text-xs">
        <!-- Back Button -->
        <div class="mb-4">
            <button @click="$router.push('/tiket')" class="flex items-center text-blue-500">
                <!-- Font Awesome for "chevron-left" -->
                <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
                <span class="ml-2 mb-5">Kembali</span>
            </button>
        </div>

        <div class="rounded-2xl bg-white p-8">
            <div>
                <div class="flex justify-between">
                    <span class="font-semibold text-base">Detail Keluhan Tiket</span>
                    <span class="font-semibold text-base">{{ ticketData.tiket_id }}</span>
                </div>
                <div class="mt-4 grid grid-cols-12 gap-4">
                    <!-- Customer Image -->
                    <div class="col-span-3">
                        <div class="flex justify-center border-r-2 border-gray-200 p-4">
                            <div class="w-40 h-40 rounded-full">
                                <div v-if="ticketData.customer_foto">
                                    <img :src="ticketData.customer_foto ? default_url + ticketData.customer_foto : default_url + 'default.png'"
                                    alt="" class="w-40 h-40 rounded-full object-cover">
                                    </div>
                                    <div v-else class="w-40 h-40 flex justify-center items-center bg-gray-200">
                                        <i class="fas fa-image text-gray-500 text-4xl"></i>
                                    </div>
                            </div>
                        </div>
                    </div>


                    <!-- Ticket Information -->
                    <div class="col-span-9 text-gray-500">
                        <div>
                            <div class="text-sm font-bold">
                                <!-- <i class="fas fa-ticket-alt mr-2"></i><h1>Informasi Tiket</h1> -->
                                <span class="font-semibold text-base">Informasi Tiket</span>
                            </div>
                            <div class="mt-2 grid grid-cols-3 gap-2">
                                <p><i class="fas fa-heading mr-2"></i>Judul Keluhan: {{ ticketData.name }}</p>
                                <p><i class="fas fa-file-alt mr-2"></i>Deskripsi Keluhan: {{ ticketData.description }}
                                </p>
                                <p><i class="fas fa-file-alt mr-2"></i>Device: {{ ticketData.device_code }} - {{
                                    ticketData.device_name }} </p>
                            </div>
                        </div>

                        <!-- Ticket Status Information -->
                        <div class="mt-4">
                            <div class="text-sm font-bold">
                                <span class="font-semibold text-base">Informasi Status</span>
                            </div>
                            <div class="mt-2 grid grid-cols-3 gap-2">
                                <p>
                                    <i class="fas fa-exclamation-circle mr-2"></i>Status Prioritas:
                                    <span :class="getPriorityBadge(ticketData.priority_name)">
                                        {{ ticketData.priority_name }}
                                    </span>
                                </p>
                                <p>
                                    <i class="fas fa-clipboard-check mr-2"></i>Status Tiket:
                                    <span :class="getStatusBadge(ticketData.status_name)">
                                        {{ ticketData.status_name }}
                                    </span>
                                </p>
                                <p>
                                    <i class="fas fa-tasks mr-2"></i>Status Pekerjaan:
                                    <span :class="getProcessStatusBadge(ticketData.status_process_name)">
                                        {{ ticketData.status_process_name }}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <!-- Customer Details -->
                        <div class="mt-4">
                            <div class="text-sm font-bold">
                                <span class="font-semibold text-base">Detail Pelanggan</span>
                            </div>
                            <!-- <div class="mt-2 grid grid-cols-3 gap-2">
                                <p><i class="fas fa-user-circle mr-2"></i>Nama Pelanggan: {{ ticketData.Customer }}</p>
                            </div> -->
                        </div>
                        <div class="mt-4">
                            <div class="text-sm font-bold">
                                <span class="font-semibold text-base">Gambar Tiket</span>
                            </div>
                            <div class="mt-2 grid grid-cols-3 gap-2">
                                <div class="col-span-3">
                                    <div v-if="ticketData.file">
                                        <img :src="getImageSrc(ticketData.file)" class="w-40 h-40 object-cover"
                                            alt="Ticket Image" />
                                    </div>
                                    <div v-else class="w-40 h-40 flex justify-center items-center bg-gray-200">
                                        <i class="fas fa-image text-gray-500 text-4xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Comments Section -->
        <div class="bg-white shadow-md rounded-lg p-4 mt-5">
            <ul class="space-y-6 overflow-y-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-100">
                <!-- Check if there are no comments -->
                <li v-if="comment.length === 0" class="text-center">
                    <div class="flex flex-col items-center">
                        <!-- Icon for No Comments -->
                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-100">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M8 10h.01M12 10h.01M16 10h.01M9 16h6M8 12h.01M12 12h.01M16 12h.01M4 8h16M4 12h16M4 16h16M4 20h16M4 4h16M4 8h.01M12 8h.01M16 8h.01M8 12h.01M12 12h.01M16 12h.01M9 16h6M8 16h.01M12 16h.01M16 16h.01M4 4h16" />
                            </svg> -->
                        </span>
                        <!-- Text for No Comments -->
                        <span class="text-gray-500">Tidak ada komentar</span>
                    </div>
                </li>
                <!-- Render comments if available -->
                <li v-for="c in visibleComments" :key="c.id" class="relative">
                    <div class="ml-6 relative">
                        <div class="flex items-start space-x-3">
                            <div class="flex-shrink-0">
                                <span
                                    class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-purple-100">
                                    <!-- <img :src="ticketData.User.file ? ticketData.User.file : default_url + 'default.png'"
                                        class="w-10 h-10 object-cover" /> -->
                                    <img :src="ticketData.user_foto ? default_url + ticketData.user_foto : default_url + 'default.png'"
                                        alt="" class="w-8 h-8 rounded-full object-cover">
                                </span>
                            </div>
                            <div class="min-w-0 flex-1 overflow-hidden">
                                <p class="text-sm font-medium text-gray-900">{{ c.User }}</p>
                                <p class="text-sm text-gray-500">{{ c.comment }}</p>
                            </div>
                            <span class="text-xs text-gray-500">{{ c.timeAgo }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- Show more/less comments button if more than 3 comments -->
            <div v-if="comment.length > 3" class="text-center">
                <button @click="toggleComments" class="text-blue-500 text-sm">
                    {{ showAllComments ? 'Lihat lebih sedikit' : 'Lihat lebih banyak' }}
                </button>
            </div>
        </div>
        <!-- Add Comment Section -->
        <div class="bg-white shadow-md rounded-lg p-4 mt-5">
            <div class="relative">
                <div class="ml-6 relative">
                    <div class="flex items-start space-x-3">
                        <!-- <img :src="ticketData.user_foto ? ticketData.user_foto : default_url + 'default.png'"
                            class="w-10 h-10 rounded-full" /> -->
                        <img :src="ticketData.user_foto ? default_url + ticketData.user_foto : default_url + 'default.png'"
                            alt="" class="w-8 h-8 rounded-full object-cover">
                        <div class="min-w-0 flex-1">
                            <textarea v-model="newComment" class="w-full border border-gray-300 rounded-md p-2 text-sm"
                                rows="3" placeholder="Add your comment..."></textarea>
                            <p v-if="commentError" class="text-red-500 text-xs mt-1">{{ commentError }}</p>
                            <!-- Error message -->
                        </div>
                    </div>
                    <div class="mt-2 flex justify-end">
                        <button @click="addComment"
                            class="bg-blue-600 text-white px-4 py-2 rounded-md text-sm">Comment</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from "vuex";
import globalMixin from "../../mixin/global";
import contractComponenrt from "../../components/contract.vue";
import moment from "moment";

export default {
    data: () => ({
        foto: "",
        ticketData: {
            id: "",
            customer_id: "",
            priority_id: "",
            users_id: "",
            ticket_statuses_id: "",
            name: "",
            description: "",
            priority_name: "",
            status_name: "",
            Customer: {
                name: "",
                email: "",
                phone: "",
                address: "",
                foto: "",
            },
            User: "",
            approvedAt: null,
            solvedAt: null,
            deletedAt: null,
        },
        defaultUrl: {
            type: String,
            default: 'default.png',
        },
        comment: [],
        newComment: "",
        commentError: "", // Error message state
        default_url: process.env.VUE_APP_IMAGES,
        showAllComments: false, // For toggling comment visibility
    }),
    mixins: [globalMixin],
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getData() {
            this.axios
                .get("v1/tiket/" + this.$route.params.id, this.config)
                .then((response) => {
                    this.ticketData = response.data;
                    console.log(this.ticketData); // Check the structure here
                    if (this.ticketData.Customer.foto) {
                        this.ticketData.Customer.foto =
                            this.default_url + this.ticketData.Customer.foto;
                    }
                    console.log(this.ticketData.customer_foto); // Periksa ID tiket
                    this.getComments();
                })
                .catch((err) => {
                    this.errorResponse(err);
                });
        },
        getComments() {
            console.log(this.$route.params.id); // Periksa ID tiket
            this.axios
                .get(`v1/comment/Byticketid/` + this.$route.params.id, this.config)
                .then((response) => {
                    this.comment = response.data.map((c) => {
                        return {
                            ...c,
                            formattedTime: this.formatDate(c.createdAt),
                        };
                    });
                })
                .catch((err) => {
                    this.errorResponse(err);
                });
        },
        addComment() {
            if (!this.newComment.trim()) {
                this.commentError = "Komentar harus diisi."; // Set error message
                return;
            }
            this.axios.post(`v1/comment/${this.$route.params.id}/createbytiketid`, {
                comment: this.newComment,
            }, this.config)
                .then((response) => {
                    this.comment.unshift(response.data);
                    this.newComment = '';
                    this.commentError = ''; // Reset error message after successful submission
                })
                .catch((err) => {
                    this.errorResponse(err);
                });
        },
        formatDate(date) {
            return moment(date).fromNow(); // e.g., "2 minutes ago"
        },
        refreshCommentTimes() {
            this.comment = this.comment.map(c => {
                return {
                    ...c,
                    formattedTime: this.formatDate(c.createdAt)
                };
            });
        },
        toggleComments() {
            this.showAllComments = !this.showAllComments;
        },
        getPriorityBadge(priority) {
            if (!priority) return '';
            switch (priority.toLowerCase()) {
                case 'low':
                    return 'inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-800';
                case 'medium':
                    return 'inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800';
                case 'high':
                    return 'inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800';
                case 'critical':
                    return 'inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-800';
                default:
                    return '';
            }
        },
        getImageSrc(file) {
            if (!file) {
                return this.defaultUrl;
            }
            // Check the image format by reading the base64 string header
            const base64Header = file.substring(0, 15).toLowerCase();
            let mimeType = 'image/png'; // Default to PNG if no specific format is found

            if (base64Header.includes('image/jpeg')) {
                mimeType = 'image/jpeg';
            } else if (base64Header.includes('image/jpg')) {
                mimeType = 'image/jpg';
            } else if (base64Header.includes('image/svg+xml')) {
                mimeType = 'image/svg+xml';
            }

            return `data:${mimeType};base64,${file}`;
        },
        getStatusBadge(status) {
            if (!status) return '';
            switch (status.toLowerCase()) {
                case 'open':
                    return 'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10';
                case 'closed':
                    return 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10';
                default:
                    return '';
            }
        },
        getProcessStatusBadge(status) {
            if (!status) return '';
            switch (status.toLowerCase()) {
                case 'on progress':
                    return 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20';
                case 'solved':
                    return 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20';
                case 'pending':
                    return 'inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10';
                case 'hold':
                    return 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10';
                case 'assigned':
                    return 'inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10';
                default:
                    return '';
            }
        },
    },
    computed: {
        visibleComments() {
            return this.showAllComments ? this.comment : this.comment.slice(0, 3);
        },
        ...mapGetters({
            token: "auth/token",
        }),
    },
    mounted() {
        this.getData();
        this.updateTimeInterval = setInterval(() => {
            this.refreshCommentTimes();
        }, 60000); // Update every minute
    },
    beforeDestroy() {
        if (this.updateTimeInterval) {
            clearInterval(this.updateTimeInterval);
        }
    },
    components: {
        contractComponenrt,
    },
};
</script>
