<template>
     <div class="rounded-2xl bg-white text-black text-center relative">
        <div class="p-4 flex flex-wrap content-center justify-center items-center" style="height:23.3rem" >
                <div class="">
                        <div class="w-full h-full">
                                <div class="flex items-center justify-center">
                                    <img src="/img/Rainy.svg" class="w-24" >

                                </div>
                                <div class="mt-2">
                                <p class="text-xl font-bold">{{parseFloat(currentDevice.precipitation).toFixed(2)}} mm</p>
                                <p class="text-gray-400 font-lg">
                                    <!-- <span v-if="currentDevice.precipitation >= 0.2 && currentDevice.precipitation < 0.4 ">Curah Hujan Rendah</span>
                                    <span v-else-if="currentDevice.precipitation < 0.19">Curah Hujan Sangat Rendah</span>
                                    <span v-else-if="currentDevice.precipitation >= 0.4 && currentDevice.precipitation < 0.6 ">Curah Hujan Sedang</span>
                                    <span v-else-if="currentDevice.precipitation >= 0.6 && currentDevice.precipitation < 0.8 ">Curah Hujan Kuat</span>
                                    <span v-else-if="currentDevice.precipitation >= 0.8 ">Curah Hujan Sangat Kuat</span> -->
                                    <span v-if="currentDevice.precipitation >= 0.2 && currentDevice.precipitation < 2.5 ">Curah Hujan Ringan</span>
                                    <span v-else-if="currentDevice.precipitation < 0.19">Curah Hujan Sangat Rendah</span>
                                    <span v-else-if="currentDevice.precipitation >= 2.6 && currentDevice.precipitation < 5 ">Curah Hujan Sedang</span>
                                    <span v-else-if="currentDevice.precipitation >= 5 ">Curah Hujan Lebat</span>

                                </p>
                            </div>
                        </div>
                
                </div>
           
        </div>
    </div>
</template>

<script>
export default {
    props:['currentDevice'],
    components:{
    },
}
</script>