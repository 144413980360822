<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                    <div class="flex flex-wrap items-center content-between justify-between">
                        <span class="font-semibold text-base">SIM</span>
                        <div class="flex">
                            <div  class="text-xs relative" > 
                                <div class="absolute top-2.5 left-2.5">
                                    <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA" ></unicon>
                                    
                                </div>

                                <input
                                v-model="keyword"
                                type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword"
                                id="keyword"
                                placeholder="Pencarian"
                            />
                            </div>
                            
                        </div>
                    
                    </div>
                    <div class="mt-4 w-full overflow-x-auto">
                        <table class="w-full">
                            <thead>
                                <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                    <td class="py-4">ICCID</td>
                                    <td>MSISDN</td>
                                    <td>Status</td>
                                    <td >Cycle Usage</td>
                                    <td >Limit</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in filterData" :key="item.id" class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                    <td class="py-4">
                                        <div class="flex items-center">
                                            <p>{{item.sim_id}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <span>-</span>
                                    </td>
                                    <td>
                                        <div  v-if="item.status === 10" class="flex items-center space-x-2">
                                            <!-- Dot -->
                                            <span class="h-2 w-2 rounded-full bg-blue-500"></span>
                                            <!-- Label -->
                                            <span class="text-sm font-medium text-gray-700">Pre-activated</span>
                                        </div>

                                        <span 
                                            v-if="item.status === 23" 
                                            class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
                                        >
                                            Active
                                        </span>
                                        <div  v-if="item.status === 30" class="flex items-center space-x-2">
                                            <!-- Dot -->
                                            <span class="h-2 w-2 rounded-full bg-yellow-500"></span>
                                            <!-- Label -->
                                            <span class="text-sm font-medium text-gray-700">Pending</span>
                                        </div>
                                    </td>
                                    <td class="">
                                        <div v-if="item.sim_service.bundles.length > 0">
                                            <div v-for="(bundle, bIndex) in item.sim_service.bundles" :key="bIndex">
                                                <p>{{ bundle.current_cycle_usage === 0 ? 'No Data' : (bundle.current_cycle_usage / 1024 / 1024).toFixed(2) + 'MB' }}</p>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>0.00MB</p>
                                        </div>
                                    </td>
                                    <td class="">
                                        <div v-if="item.sim_service.bundles.length > 0">
                                            <div v-for="(bundle, bIndex) in item.sim_service.bundles" :key="bIndex">
                                                <p>{{ bundle.data_limit === 0 ? 'No Data' : (bundle.data_limit / 1024 / 1024).toFixed(2) + 'MB/ Year' }}</p>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>0.00MB</p>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                        <div class="flex justify-end items-center mt-4">

                            <div class="flex items-center ">
                                <div class="font-medium">
                                <span>{{startNumber}}-{{endNumber}} of {{totalRows}}</span> 
                                </div>
                                <div class="flex">
                                    <unicon name="angle-left" class="cursor-pointer" :fill="currentPage > 1 ? '#00000' : '#A1A1AA'"  @click="previousPage()"></unicon>
                                    <unicon name="angle-right" class="cursor-pointer" :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                                </div>
                            </div>

                        </div>
                    </div>
                  
                    <!-- <deleteComponent v-on:closeModal="closeModal" v-on:deleteUser="deleteUser()" v-if="modal_dialog"/> -->
                    
                    <!-- <t-modal

                    :header="`Hapus Data`"
                    class="flex flex-wrap content-center"
                    v-model="modal_dialog"
                    style="z-index:110000 !important"
                    :classes="class_modal"
                    >
                        <p class="text-lg">Yakin Hapus Data ?</p> 
                        <div class="grid grid-cols-2 gap-4 mt-4">
                            <button
                            @click="closeModal"
                            type="submit"
                            :class="`col-span-1 text-center py-2 rounded
                         
                            bg-gray-100
                            
                            disabled:opacity-50
                            focus:outline-none my-1`"


                        >Batal</button>
                        <button
                            @click="deleteUser()"
                            type="submit"
                            :class="`col-span-1 text-center py-2 rounded
                            text-white
                             merah
                            disabled:opacity-50
                            focus:outline-none my-1`"


                        >Hapus</button>
                        </div>
                        
                    </t-modal> -->
            </div>
        </div>
       
    </div>
    
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import deleteComponent from '../../components/global/deleteComponent.vue'
export default {
    data: () => ({
            data: [],
        totalRows:100,
        perPage:10,
        limit:10,
        startNumber:1,
        endNumber:8,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        getData(){
             let headers = { headers:{
            'Authorization': 'Bearer ' + this.token,
            }}
            this.axios.get('v1/cube/iot?page_no=' + this.currentPage + `&page_size=${this.limit}&keyword=${this.keyword}`,headers)
            .then((ress) => {
                this.data = ress.data.data
                this.totalRows = ress.data.total_count
                 if (this.totalRows <= this.limit) {
                    this.endNumber = this.totalRows
                } else if(this.currentPage > 1 ) {
                    let page = this.currentPage - 1
                    this.startNumber = page * this.limit + 1
                    this.endNumber = this.currentPage * this.limit
                    if (this.totalRows < this.endNumber) {
                        this.endNumber = this.totalRows
                    }

                } else {
                    this.startNumber = 1
                    this.endNumber = this.limit
                }
                this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1 ) * this.limit + 1
            })
             .catch((err) => {
                this.errorResponse(err)
            })
        },
        openDelete(id) {
            this.delete_id = id
            this.closeModal()
        },
         eventChange(event){
             this.default_url = ''
           const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load',()=>{
                this.data.foto=fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
       },
       ...mapActions({
           setUser:'auth/setUser'
       }),
 
    },
    computed:{
        ...mapGetters({
            user:'auth/user',
            token:'auth/token',
        }),
        filterData() {
            // Filter data berdasarkan kata kunci
            const query = this.keyword.toLowerCase();
            return this.data.filter((item) => {
                // Filter berdasarkan sim_id, status, atau bundle ID
                return (
                    item.sim_id.toLowerCase().includes(query) || // Filter sim_id
                    item.status.toString().includes(query) || // Filter status
                    item.sim_service.bundles.some((bundle) =>
                        bundle.id.toLowerCase().includes(query)
                    ) // Filter ID di dalam bundle
                );
            });
        },
       
    },
    created() {
        this.getData()
        
       
   
    },
    sockets: {
      
    },
    
    mounted() {
       
    },
    components:{
        ValidationProvider,ValidationObserver,deleteComponent
    }
}
</script>